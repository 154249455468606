import DayJS from "dayjs";
import React, {useEffect, useState} from "react";
import DataRow from "../../../components/common/DataRow";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import {APIRequest} from "../../../lib/_sso";
import AppConfig from "../../../_appConfig";
import {useSocketIo} from "../../../lib/socketio-with-auth";

const server = AppConfig.api_server;

const D4H_Migration = ({ auth }) => {
  const socketIo = useSocketIo();

  const [apiKey, setApiKey] = useState("");
  const [whoAmI, setWhoAmI] = useState({});
  const [wipeDestination, setWipeDestination] = useState(false);
  const [pushToCentral, setPushToCentral] = useState(false);

  const [isImportRunning, setIsImportRunning] = useState(false);
  const [importCompleteData, setImportCompleteData] = useState("")
  const [logs, setLogs] = useState([
    [new Date(), "Importer is ready"]
  ]);

  useEffect(() => {
    if (!socketIo) return;

    const handleNewLogEntry = (logData) => {
      setLogs((currentLogs) => {
        return [logData].concat(currentLogs);
      });
    };
    const handleComplete = (data) => {
      setImportCompleteData(data ? JSON.stringify(data) : "");
      setIsImportRunning(false);
    }

    socketIo.on("import:progress", handleNewLogEntry);
    socketIo.on("import:complete", handleComplete);
    return () => {
      socketIo.removeListener("import:progress", handleNewLogEntry);
      socketIo.removeListener("import:complete", handleComplete);
    }
  }, [socketIo]);

  const handleCheckApiKey = () => {
    APIRequest.post(`${server}/settings/importer/d4h/key_check`, {
      apiKey
    }, {
      params: {
        token: auth.token
      }
    }).then(res => {
      if(res.data !== false) setWhoAmI(res.data);
      setIsImportRunning(false);
    }).catch(err => {

    })
  }

  const handleDataImport = () => {
    setIsImportRunning(true);
    setLogs([]);

    socketIo.emit("import:d4h:data", {
      token: auth.token,
      wipeDestination,
      pushToCentral,

      apiKey,
      teamId: whoAmI.members[0].owner.id
    });
  }

  return (
    <div className="flex-1">

      {whoAmI.account !== undefined ? <>
        <DataRow label={"D4H User"} value={whoAmI.members[0].name} />
        <DataRow label={"D4H Organisation"} value={whoAmI.members[0].owner.title} />

        <div className="">
          <label className="labelClass">Update Team Members Central Accounts? (i.e. this TeamSite should become source of truth after import)</label>
          <ToggleSwitch
            disabled={isImportRunning}
            isChecked={pushToCentral}
            label1={"No"}
            label2={"Yes"}
            onChange={() => setPushToCentral(!pushToCentral)}
          />
        </div>
        <div className="">
          <label className="labelClass">Acknowledge that the destination will be wiped before import begins?</label>
          <ToggleSwitch
            disabled={isImportRunning}
            isChecked={wipeDestination}
            label1={"No"}
            label2={"Yes"}
            onChange={() => setWipeDestination(!wipeDestination)}
          />
        </div>


        <div className="text-center">
          {isImportRunning && <>
            <div className={"border rounded my-4 p-4 text-xl"}>Importing data... please wait</div>
          </>}
          {importCompleteData && <>
            <label>Import results</label>
            <textarea rows={5} className={"w-full border rounded dark:text-black p-2"} value={JSON.stringify(JSON.parse(importCompleteData), null, 2)}></textarea>
          </>}
        </div>
        <div className={"border rounded my-2 p-1 overflow-y-auto h-60"}>{logs.map((item, index) => {
          return (<div key={index} className={"pb-1"}>
            <span className={"text-sm mr-3"}>{DayJS(item[0]).format("DD-MM-YYYY hh:mm:sss a")}</span> {item[1]}
          </div>);
        })}</div>
        <div className="text-right">
          <button
            disabled={isImportRunning || !wipeDestination}
            onClick={handleDataImport}
            className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-white hover:border-green-300 disabled:cursor-not-allowed disabled:opacity-50"
          >Begin</button>
        </div>

      </> : <div>
        <label className="labelClass">D4H API Key</label>
        <input
          onChange={(e) => setApiKey(e.target.value)}
          value={apiKey}
          name="apiKey"
          className="inputClass"
        />
        <div className="pt-2 text-right">
          <button
            disabled={apiKey === ""}
            onClick={handleCheckApiKey}
            className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-white hover:border-green-300 disabled:cursor-not-allowed disabled:opacity-50"
          >Check API Key</button>
        </div>
      </div>}

    </div>
  );
}
export default D4H_Migration;