import React, { useState } from "react";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import SettingsReceiver from "../../contexts/settings_context/Settings_Receiver";
import OrganisationReciever from "../../contexts/organisation/receiver";
import ChainedContext from "../../contexts/chainedContext";
import { toast } from "react-hot-toast";
import API from "../../api";
import Box from "../../components/common/Box";
import SettingsArrayList from "./_components/SettingsArrayList";
import SettingsColourList from "./_components/SettingsColourArrayList";
import StationsList from "./_components/StationsList";
import CalendarFeeds from "./_components/CalendarFeeds";
import {AuthComponents} from "../../lib/_sso";

const SettingsIndex = ({ settings }) => {
  const [isSyncing, setIsSyncing] = useState(false);

  const handleSync = () => {
    setIsSyncing(true);
    const isLoading = toast.loading("Syncing with SarStuff Central...");
    API.settings.syncCentralMembers().then(() => {
      toast.success("Successfully synced with SarStuff Central")
    }).catch((err) => {
      toast.error("An error occurred while syncing with SarStuff Central, Error: " + (err.response?.data?.message || err.message));
    }).finally(() => {
      setIsSyncing(false);
      toast.dismiss(isLoading);
    });
  };
  const handleRoleSync = () => {
    setIsSyncing(true);
    const isLoading = toast.loading("Syncing member roles with SarStuff Central...");
    API.settings.syncMembersRoles().then(() => {
      toast.success("Successfully synced member roles with SarStuff Central")
    }).catch((err) => {
      toast.error("An error occurred while syncing member roles with SarStuff Central, Error: " + (err.response?.data?.message || err.message));
    }).finally(() => {
      setIsSyncing(false);
      toast.dismiss(isLoading);
    });
  }
  const onSubmit = (data) => {
    const isLoading = toast.loading("Updating settings...");
    API.settings.updateSystem(data).then(() => {
      toast.success("Settings updated");
      settings._refresh();
    }).catch(err => {
      toast.error("An error occurred while updating settings, Error: " + (err.response?.data?.message || err.message))
    }).finally(() => {
      toast.dismiss(isLoading);
    });
  };

  return (
    <div className="space-y-2 p-2">
      <div className="grid gap-4 pr-4 xl:grid-cols-3">
        <div className="space-y-4">
          <AuthComponents.Can scope="teamsite:admin">
            <Box title="SarStuff Central">
              <div className="p-4 flex gap-4 justify-center">
                <button onClick={handleSync} className={"rounded border bg-primary p-2 text-white"} disabled={isSyncing}>
                  Full Member Sync
                </button>
                <button onClick={handleRoleSync} className={"rounded border bg-primary p-2 text-white"} disabled={isSyncing}>
                  Sync Member Roles
                </button>
              </div>
              {isSyncing && (
                <div className={"m-2 rounded border bg-ss p-2 text-white"}>
                  Syncing in progress...
                </div>
              )}
            </Box>
          </AuthComponents.Can>
          <div>
            <SettingsArrayList
              title="Location Types"
              field="locTypes"
              data={settings.locTypes}
              onSubmit={onSubmit}
              onDelete={onSubmit}
            />
            <SettingsArrayList
              title="Awards"
              field="awards"
              data={settings.awards}
              onSubmit={onSubmit}
              onDelete={onSubmit}
            />
          </div>
        </div>
        <div>
          <CalendarFeeds
            onSubmit={onSubmit}
          />
          <SettingsColourList
            title="Calendar Event Types"
            field="event_types"
            data={settings.event_types}
            onSubmit={onSubmit}
            onDelete={onSubmit}
          />
        </div>
        <div>
          <Box title="System Toggles">
            <div className={"p-3"}>
              <label className="labelClass">Prompt Missing Competencies/Course Roles?</label>
              <ToggleSwitch
                isChecked={settings.promptMissingCompetenciesRoles}
                label1={"No"}
                label2={"Yes"}
                onChange={() => {
                  onSubmit({promptMissingCompetenciesRoles: !settings.promptMissingCompetenciesRoles});
                }}
              />
              <div className={"text-sm mt-2"}>
                If enabled this will show a warning message when adding scores to a team member who does not have all the roles linked to the competency/course.
              </div>
            </div>
            <div className={"p-3"}>
              <label className="labelClass">Enable Lowland Report?</label>
              <ToggleSwitch
                isChecked={settings.enableLowlandReport}
                label1={"No"}
                label2={"Yes"}
                onChange={() => {
                  onSubmit({enableLowlandReport: !settings.enableLowlandReport});
                }}
              />
              <div className={"text-sm mt-2"}>
                Enable this if your are a Lowland Rescue team.
              </div>
            </div>
            <div className={"p-3"}>
              <label className="labelClass">Upcoming events feed mode</label>
              <ToggleSwitch
                isChecked={settings.nextFewEvents}
                label1={"Next 3 weeks"}
                label2={"Next 10 events"}
                onChange={() => {
                  onSubmit({nextFewEvents: !settings.nextFewEvents});
                }}
              />
              <div className={"text-sm mt-2"}>
                This will change the number of events are are listed on the mobile app home screen.
              </div>
            </div>
          </Box>
          <StationsList title={"Stations"} />
          <SettingsArrayList
            title="Sections"
            field="sections"
            data={settings.sections}
            onSubmit={onSubmit}
            onDelete={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};
export default ChainedContext(SettingsIndex, [
  [SettingsReceiver, "settings"],
  [OrganisationReciever, "organisation"],
]);
