import { APIRequest } from "../lib/_sso";
import AppConfig from "../_appConfig";

const server = AppConfig.api_server;

export default {
  getFuture: () =>
    APIRequest.get(`${server}/calendar/events/future`).then((res) => res.data),
  retrieveAll: () =>
    APIRequest.get(`${server}/calendar`).then((res) => res.data),
  retrieveAllCompetenciesDates: () =>
    APIRequest.get(`${server}/calendar/training/competencies`).then((res) => res.data),
  retrieveAllCourseDates: () =>
    APIRequest.get(`${server}/calendar/training/courses`).then((res) => res.data),
  retrieveAllRecruitmentEvents: () =>
    APIRequest.get(`${server}/calendar/recruitment_events`).then(res => res.data),
  createEvent: (data) =>
    APIRequest.post(`${server}/calendar`, data).then((res) => res.data),
  updateEvent: (id, data) =>
    APIRequest.patch(`${server}/calendar/${id}`, data).then((res) => res.data),
  deleteEvent: (id) =>
    APIRequest.delete(`${server}/calendar/${id}`).then((res) => res.data),
  getEvent: (id) =>
    APIRequest.get(`${server}/calendar/event/${id}`).then((res) => res.data),
  printEvent: (id) =>
    APIRequest.get(`${server}/calendar/event/${id}/print`, {responseType: "blob"}).then((res) => res.data),
  retrieveTrainingEvent: (eventId) =>
    APIRequest.get(`${server}/calendar/training/competencies/${eventId}`),
  retrieveAttendance: event_id =>
    APIRequest.get(`${server}/calendar/event/${event_id}/attendance`).then(res => res.data),
  updateAttendanceResult: (event_id, member_id, data) =>
    APIRequest.patch(`${server}/calendar/event/${event_id}/attendance/${member_id}/result`, data),
  markResponse: (eventID, data) =>
    APIRequest.post(`${server}/calendar/event/${eventID}/attendance`, data),
  retrieveFeedUrls: (feedType) =>
    APIRequest.get(`${server}/calendar/feed/retrieve_link/${feedType}`).then((res) => res.data),
};