import React, { useState, useEffect, useRef } from "react";
import Geosuggest from "react-geosuggest";
import { Authentication } from "../../../lib/_sso";
import { useNavigate } from "react-router-dom";
import Box from "../../../components/common/Box";
import Layout from "../../../components/common/Layout";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import DataRow from "../../../components/common/DataRow";
import api from "../../../api";

export default function AddSupplier() {
  const navigate = useNavigate();

  const _geoSuggest = useRef(null);
  const [supplier, setSupplier] = useState({
    name: "",
    phone: "",
    details: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    type: [],
  });
  const [country, setCountry] = useState("");
  const [settings, setSettings] = useState({
    tags: [],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let user_details = Authentication.getUserData();
    setCountry(user_details.organisation.country);

    api.assets.settings.all().then((res) => {
      setSettings(res);
    });
  }, []);

  const validate = (data) => {
    const errors = {};
    if (!data.name) errors.name = "Can't be empty";
    if (data.type.length === 0) errors.type = "Can't be empty";
    return errors;
  };
  const onSupplierChange = (e) => {
    const newData = { ...supplier, [e.target.name]: e.target.value };
    const errors = validate(newData);
    setErrors(errors);
    setSupplier(newData);
  };
  const AddSupplier = () => {
    const errors = validate(supplier);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      api.assets.suppliers.add(supplier).then((res) => {
        navigate(`/asset/suppliers/${res._id}`);
      });
    }
  };
  const onSuggestSelect = (suggest) => {
    if (suggest) {
      let coords = [suggest.location.lng, suggest.location.lat];
      let components = suggest.gmaps.address_components;
      let admin_area = "";
      let postal_town = "";
      let postal_code = "";
      // eslint-disable-next-line
      let name = "";
      // eslint-disable-next-line
      let street = "";
      let county = "";
      let town = "";
      if (suggest.gmaps.name) {
        name = suggest.gmaps.name;
      }

      for (let component = 0; component < components.length; component++) {
        if (components[component].types[0] === "premise" && name === "") {
          name = components[component].long_name;
        }
        if (components[component].types[0] === "locality") {
          admin_area = components[component].long_name;
        }
        if (components[component].types[0] === "postal_town") {
          postal_town = components[component].long_name;
        }
        if (components[component].types[0] === "administrative_area_level_2") {
          county = components[component].long_name;
        }
        if (components[component].types[0] === "postal_code") {
          postal_code = components[component].long_name;
        }
        if (admin_area) {
          town = admin_area;
        } else {
          town = postal_town;
        }
      }

      setSupplier({
        ...supplier,
        address: {
          geoJSON: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coords,
            },
            properties: {
              name: name,
              town: town,
              county: county,
              postcode: postal_code,
              address: suggest.gmaps.formatted_address,
              // grid: this.getGrid(suggest.location.lat, suggest.location.lng),
            },
          },
        },
      });
    }
  };
  const manageTags = (data) => {
    let newData;
    if (supplier.type.includes(data)) {
      newData = {
        ...supplier,
        type: supplier.type.filter((t) => t !== data),
      };
    } else {
      newData = {
        ...supplier,
        type: supplier.type.concat([data]),
      };
    }
    setSupplier(newData);

    const errors = validate(newData);
    setErrors(errors);
  };
  return (
    <Layout>
      <div className="flex gap-4">
        <Box title="Details">
          <div className="p-2">
            <div className="flex flex-col gap-4">
              <Input
                error={errors.name}
                label={"Name"}
                field="name"
                type={"text"}
                value={supplier.name}
                onChange={onSupplierChange}
                placeholder="SarStuff..."
              />
              <Input
                label={"Phone"}
                field="phone"
                type={"text"}
                value={supplier.phone}
                onChange={onSupplierChange}
                placeholder="Main phone..."
              />
              <div className="flex flex-row gap-2">
                <Input
                  label={"Contact Name"}
                  field="contactName"
                  type={"text"}
                  value={supplier.contactName}
                  onChange={onSupplierChange}
                  placeholder="Joe Bloggs..."
                />
                <Input
                  label={"Contact Phone"}
                  field="contactPhone"
                  type={"text"}
                  value={supplier.contactPhone}
                  onChange={onSupplierChange}
                  placeholder="07973....."
                />
              </div>
              <Input
                label={"Contact Email"}
                field="contactEmail"
                type={"text"}
                value={supplier.contactEmail}
                onChange={onSupplierChange}
                placeholder="jb@sarstuff..."
              />
              <div className="">
                <label className={`labelClass`}>Details</label>
                <textarea
                  rows={3}
                  name="details"
                  value={supplier.details}
                  onChange={onSupplierChange}
                  className={`inputClass`}
                />
              </div>
            </div>
          </div>
        </Box>
        <Box title="Address">
          <div className="p-2">
            <div className="flex flex-col">
              <Geosuggest
                autoComplete="off"
                ref={_geoSuggest}
                placeholder="Molash, Kent"
                inputClassName="inputClass w-full"
                country={country}
                onSuggestSelect={onSuggestSelect}
                suggestsClassName="bg-gray-900 "
                suggestsHiddenClassName="geosuggest__suggests--hidden"
                suggestItemClassName=" p-2 border-b border-gray-600 cursor-pointer hover:bg-gray-800"
              />
              {supplier.address && (
                <div className="flex flex-col gap-4 ">
                  <DataRow
                    label={"Name"}
                    value={supplier.address.geoJSON.properties.name}
                  />
                  <DataRow
                    label={"Address"}
                    value={supplier.address.geoJSON.properties.address}
                  />
                  <DataRow
                    label={"Town"}
                    value={supplier.address.geoJSON.properties.town}
                  />
                  <DataRow
                    label={"Postcode"}
                    value={supplier.address.geoJSON.properties.postcode}
                  />
                </div>
              )}
            </div>
            <div className="mt-4 flex flex-col justify-around border-t border-gray-400 p-4 ">
              <label
                className={`text-md block text-center font-medium text-yellow-700 `}
              >
                Type
              </label>
              {errors.type && (
                <span className="text-red-500">Please pick supplier type</span>
              )}
              <div className="grid grid-cols-4 justify-around p-4">
                {settings && settings.tags ? (
                  settings.tags.map((t, i) => {
                    let active = supplier.type.includes(t);
                    return (
                      <span
                        onClick={() => manageTags(t)}
                        key={i}
                        className={`m-1 cursor-pointer rounded-lg bg-gray-700 p-2 text-white hover:bg-gray-600 ${
                          active && " bg-green-500 hover:bg-green-600"
                        }`}
                      >
                        {t}
                      </span>
                    );
                  })
                ) : (
                  <div className={"text-red-500"}>
                    There are no supplier types defined in asset settings
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4 flex justify-around border-t border-gray-400 p-4 ">
              {/*<Button text="Cancel" negative style={"text-2xl"} />*/}
              <Button
                text="Add Supplier"
                positive
                style={"text-2xl"}
                onClick={AddSupplier}
              />
            </div>
          </div>
        </Box>
      </div>
    </Layout>
  );
}
