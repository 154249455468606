import React, { useState } from "react";
import Box from "../../../components/common/Box";
import Input from "../../../components/common/Input";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/20/solid";
import { Skeleton } from "../../../components/ui/skeleton";
import { PencilIcon } from "@heroicons/react/24/outline";
import Select from "../../../components/common/Select";
import API from "../../../api";
import roleList from "./RoleList";

const CompList = ({
  title,
  field,
  roles,
  data,
  refresh,
  loading = false,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [description, setDescription] = useState("");
  const [validFor, setValidFor] = useState(0);
  const [compRoles, setCompRoles] = useState([]);
  const [editID, setEditID] = useState(null);
  const [del, setDelete] = useState("");
  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);

  const handleEdit = (item) => {
    setName(item.name);
    setDescription(item.description);
    setValidFor(item.validFor);
    setCompRoles(item.roles);
    setEditID(item._id);
    setIsEdit(true);
  };
  const addComp = () => {
    const data = {
      name: name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      }),
      description: description,
      validFor: validFor,
      roles: compRoles,
    };
    API.settings.addComp(data).then(() => {
      refresh();
    });
    setName("");
    setCompRoles([]);
    setDescription("");
    setValidFor(0);
  };
  const updateComp = () => {
    const data = {
      name: name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      }),
      description: description,
      validFor: validFor,
      roles: compRoles,
    };
    API.settings.updateComp(editID, data).then(() => {
      refresh();
    });
    setName("");
    setCompRoles([]);
    setDescription("");
    setValidFor(0);
    setIsEdit(false);
    setEditID(null);
  };

  const handleDelete = () => {
    setError(null);
    API.settings.deleteComp(del._id).then(() => {
      setIsDeletePromptOpen(false);
      refresh();
    }).catch((err) => {
      let errorMessage = "There has been and error";
      if (err.isAxiosError !== undefined) {
        if (err.response.data.message !== undefined) {
          errorMessage = err.response.data.message;
        }
      }
      setError(errorMessage);
    });
  };
  const DeletePrompt = () => {
    return (
      <Transition
        show={isDeletePromptOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isDeletePromptOpen}
          onClose={() => setIsDeletePromptOpen(false)}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4 text-center">
                <Dialog.Title className={"text-2xl"}>
                  Delete Competency{" "}
                  <span className="font-bold italic">{del.name}</span>
                </Dialog.Title>
                <div className="py-4 text-ss">
                  This action is not reversible so please be sure!{del._totalSessions >=1 && ` This will also delete ${del._totalSessions} linked competency member record(s)`}
                </div>
                {error && (
                  <div className="py-4 text-xl text-red-500">{error}</div>
                )}
              </div>

              <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-white"
                  onClick={() => setIsDeletePromptOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-red-500 px-4 py-2 text-white disabled:bg-red-500"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };
  const addRoles = e => setCompRoles(a => a.concat([e]));
  const removeRole = e => setCompRoles(compRoles.filter(b => b !== e));

  return (
    <Box title={title}>
      <div className="flex w-full flex-col ">
        <div className="flex w-full items-start gap-2 p-2">
          <div className="w-full">
            <div>
              <label className="labelClass">Competency</label>
              <Input
                value={name}
                type={"text"}
                field={field}
                onChange={(e) => setName(e.target.value)}
                placeholder="role..."
              />
            </div>
          </div>
          <div className="w-full">
            <div>
              <label className="labelClass">Valid For (years)</label>
              <Input
                value={validFor}
                type={"number"}
                onChange={(e) => setValidFor(e.target.value)}
                placeholder="1"
              />
            </div>
          </div>
          <div className=" w-full items-center ">
            <label className="labelClass">Roles</label>
            <div className="   ">
              <Select
                options={
                  (roles !== undefined &&
                    roles
                      .filter((item) => {
                        if (compRoles !== undefined) {
                          return compRoles.indexOf(item.name) === -1;
                        }
                        return true;
                      })
                      .map((item, index) => {
                        return {
                          key: index,
                          text: item.name,
                          value: item.name,
                        };
                      })) ||
                  []
                }
                value=""
                onChange={(e) => addRoles(e.target.value)}
              />
              <div className="flex gap-2 flex-wrap pt-1">
                {compRoles.map((c, i) => {
                  return (
                    <div
                      key={i}
                      className="rounded p-1 text-sm dark:bg-zinc-700"
                    >
                      {c} <button onClick={() => removeRole(c)}>X</button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="  pt-8 text-right">
            <button
              disabled={name === ""}
              onClick={isEdit ? updateComp : addComp}
              className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40   px-4 py-2 text-green-500  hover:border-green-300"
            >
              {isEdit ? "Update" : "Add"}
            </button>
          </div>
        </div>
        {DeletePrompt()}
        <div className="w-full pt-4">
          {loading && (
            <div className="col-span-2 space-y-2">
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
              <Skeleton className="h-10 w-full bg-zinc-200 p-4 dark:bg-zinc-700"></Skeleton>
            </div>
          )}
          {!loading && (
            <table className="tableClass">
              <thead className="tableHeadClass">
                <tr className="tableHeadRowClass">
                  <th className="tableHeadCellClass">Name</th>
                  {/* <th className="">Description</th> */}
                  <th className="tableHeadCellClass">Roles</th>
                  <th className="tableHeadCellClass">
                    valid For <span className="text-xs italic">(years)</span>
                  </th>
                  <th className="tableHeadCellClass"></th>
                </tr>
              </thead>
              <tbody className="tableBodyClass">
                {data &&
                  data.toSorted((a, b) => {
                    if(a.name > b.name) return 1;
                    if(a.name < b.name) return -1;
                    return 0;
                  }).map((d) => {
                    return (
                      <tr className="tableBodyRowClass" key={d._id}>
                        <td className="tableBodyCellClass">{d.name}</td>
                        <td className="tableBodyCellClass">
                          <div className="flex  gap-2">
                            {d.roles.map((r, i) => {
                              return (
                                <div key={i} className="m-1 rounded p-1 dark:bg-zinc-700">
                                  {r}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        <td className="tableBodyCellClass text-center">
                          {d.validFor}
                        </td>
                        <td className="tableBodyCellClass w-32 space-x-1 text-center">
                          <button
                            // disabled={d.isAvailableDefault || d.isAssignedDefault}
                            onClick={() => handleEdit(d)}
                            className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
                          >
                            <PencilIcon className="h-6 w-6 text-yellow-200 " />
                          </button>
                          <button
                            className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                            onClick={() => {
                              setDelete(d);
                              setIsDeletePromptOpen(true);
                            }}
                          >
                            <TrashIcon className="h-6 w-6 text-red-200" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Box>
  );
};
export default CompList;
