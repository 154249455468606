import React, { useCallback, useEffect, useState } from "react";
import Toast from "react-hot-toast";
import { TrashIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import Box from "../../../components/common/Box";
import Select from "../../../components/common/Select";
import { AuthComponents } from "../../../lib/_sso";
import API from "../../../api";
import {Dialog, Transition} from "@headlessui/react";
import roleList from "./RoleList";

const TeamRequirements = () => {
  const [requirements, setRequirements] = useState([]);
  const [rolesList, setRolesList] = useState([]); // all pickable roles
  const [roles, setRoles] = useState([]);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [validFor, setValidFor] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [del, setDelete] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleGetData = useCallback(() => {
    API.settings.getRoles().then(a => {
      setRolesList(a.filter(b => b.requiresTeamRequirement));
    }).catch((err) => {
      Toast.error("Unable to retrieve roles from the server at this time");
    });
    API.settings.requirements
      .all()
      .then((res) => {
        setRequirements(res);

        setIsEdit(false);
        setEditId(null);
        setTitle("");
        setType("");
        setRoles([]);
        setValidFor(0);
      })
      .catch((err) => {
        Toast.error("Unable to retrieve data from the server at this time");
      });
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    if (editId) {
      const data = requirements.find((a) => a._id === editId);
      if (data) {
        setTitle(data.title);
        setType(data.type);
        setValidFor(data.validFor || 0);
        setRoles(data.roles || []);
      }
    }
  }, [editId, requirements]);

  const showDeletePrompt = (data) => {
    setDelete(data);
    setIsDeleteOpen(true);
  };
  const hideDeletePrompt = () => {
    setDelete(null);
    setIsDeleteOpen(false);
  };

  const addRole = e => setRoles(a => a.concat([e]));
  const removeRole = e => setRoles(roles.filter(b => b !== e));

  const handleDelete = () => {
    API.settings.requirements.delete(del._id).then(() => {
      Toast.success("Successfully deleted requirement");
      handleGetData();
      hideDeletePrompt();
    }).catch((err) => {
      Toast.error("Unable to delete requirement, error: " + err.message);
    });
  };
  const handleSubmit = () => {
    const data = {
      title,
      type,
      validFor,
      roles
    };
    if (isEdit) {
      API.settings.requirements
        .update(editId, data)
        .then(() => {
          Toast.success("Successfully updated requirement");
          handleGetData();
        })
        .catch((err) => {
          Toast.error("Unable to save data, error: " + err.message);
        });
    } else {
      API.settings.requirements
        .create(data)
        .then(() => {
          Toast.success("Successfully create new requirement");
          handleGetData();
        })
        .catch((err) => {
          Toast.error("Unable to save data, error: " + err.message);
        });
    }
  };

  const RenderDeleteConfirmDialog = () => {
    if(!isDeleteOpen) return;

    return (
      <Transition
        show={true}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={true}
          onClose={() => hideDeletePrompt()}
          className="fixed inset-0 z-10 overflow-y-auto "
        >
          <div className="min-h-screen text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
            <div className="my-8 inline-block w-full max-w-2xl transform overflow-hidden rounded-2xl border-4 border-gray-700 bg-gray-50 p-6 text-left align-middle shadow-xl transition-all dark:bg-zinc-700">
              <div className="pt-4">
                <Dialog.Title>
                  Delete Requirement{" "}<span className="font-bold italic">{del?.title}</span>?
                </Dialog.Title>
                <Dialog.Description className={"mt-3"}>WARNING: This will also wipe existing data against each member/recruit linked to this requirement.</Dialog.Description>
              </div>

              <div className="mt-4 flex justify-between border-t border-gray-700 pt-4">
                <button className="rounded-lg bg-primary px-4 py-2 text-white" onClick={() => hideDeletePrompt()}>Cancel</button>
                <button className="rounded-lg bg-red-500 px-4 py-2 text-white" onClick={handleDelete}>Delete</button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <Box title={"Team Requirements"}>
      {RenderDeleteConfirmDialog()}
      <div className="flex w-full flex-col ">
        <div className="grid w-full items-center gap-2 p-2 align-middle xl:grid-cols-6">
          <div className="xl:col-span-2">
            <label className="text-center text-sm text-zinc-400">Title</label>
            <input
              value={title}
              className="w-full rounded-md border-gray-400 bg-white p-2 shadow-sm dark:bg-zinc-700"
              type={"text"}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="membership..."
            />
          </div>
          <div className="flex flex-col items-center">
            <label className="pb-1 text-center text-sm text-zinc-400">
              Type
            </label>
            <div className="flex">
              <div className="items-centre flex">
                <Select
                  options={[
                    { key: 1, text: "Membership", value: "membership" },
                    { key: 2, text: "Vetting", value: "vetting" },
                    { key: 3, text: "Course/Other", value: "course" },
                  ]}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="sm:col-span-1">
            <label className=" text-center text-sm text-zinc-400">
              Valid For (years)
            </label>
            <input
              value={validFor}
              className="w-full rounded-md  border-gray-400 bg-white p-2 shadow-sm dark:bg-zinc-700"
              type={"number"}
              onChange={(e) => setValidFor(e.target.value)}
              placeholder="1"
            />
          </div>
          <div className="w-full items-center">
            <label className="labelClass">Roles</label>
            <div>
              <Select
                options={rolesList.filter((item) => {
                  if (roles !== undefined) {
                    return roles.indexOf(item._id) === -1;
                  }
                  return true;
                }).map((item, index) => {
                  return {
                    key: item._id,
                    text: item.name,
                    value: item._id,
                  };
                })}
                value=""
                onChange={(e) => addRole(e.target.value)}
              />
              <div className="flex gap-2 flex-wrap pt-1">
                {rolesList.filter(a => roles.indexOf(a._id) !== -1).map((c, i) => {
                  return (
                    <div
                      key={i._id}
                      className="rounded p-1 text-sm dark:bg-zinc-700"
                    >
                      {c.name} <button onClick={() => removeRole(c._id)}>X</button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="pt-6 text-right">
            <button
              disabled={title === "" || type === ""}
              onClick={handleSubmit}
              className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40   px-4 py-2 text-green-500  hover:border-green-300"
            >
              {isEdit ? "Update" : "Add"}
            </button>
          </div>
        </div>
        <div className="w-full pt-4">
          <table className="tableClass">
            <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass">Title</th>
                <th className="tableHeadCellClass">Type</th>
                <th className="tableHeadCellClass">Valid For</th>
                <th className="tableHeadCellClass">Roles</th>
                <th className="tableHeadCellClass"></th>
              </tr>
            </thead>
            <tbody className="tableBodyClass">
              {requirements.sort((a, b) => {
                if(a.title > b.title) return 1;
                if(a.title < b.title) return -1;
                return 0;
              }).map((d, i) => {
                return (
                  <tr className="tableBodyRowClass" key={i}>
                    <td className="tableBodyCellClass text-lg">{d.title}</td>
                    <td className="tableBodyCellClass w-48 text-center text-lg">
                      {d.type}
                    </td>
                    <td className="tableBodyCellClass w-20 text-center text-lg">
                      {d.validFor}
                    </td>
                    <td className="tableBodyCellClass w-20 text-center text-lg">
                      <div className="flex gap-2">
                        {d.roles.map((r, i) => {
                          const fullRole = rolesList.find(aa => aa._id === r);
                          return (
                            <div
                              key={i}
                              className="m-1  rounded p-1 dark:bg-zinc-700"
                            >
                              {fullRole?.name}
                            </div>
                          );
                        })}
                      </div>
                    </td>
                    <td className="tableBodyCellClass w-32 space-x-1 text-center">
                      <button
                        onClick={() => {
                          setEditId(d._id);
                          setIsEdit(true);
                        }}
                        className="rounded-md border bg-primary bg-opacity-80  px-2 py-1 text-sm  text-white hover:bg-gray-800 disabled:opacity-20"
                      >
                        <PencilIcon className="h-6 w-6 text-yellow-200 " />
                      </button>
                      <button
                        className="rounded-md border border-red-400 bg-red-700 bg-opacity-80 px-2 py-1 text-sm text-white hover:border-red-300 hover:bg-red-800 disabled:opacity-20"
                        onClick={() => showDeletePrompt(d)}
                      >
                        <TrashIcon className="h-6 w-6 text-red-200" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};
export default TeamRequirements;
