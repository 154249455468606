import React, { useEffect, useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import { CompactPicker } from "react-color";
import DayJS from "dayjs";
import Toast from "react-hot-toast";
import API from "../../api";
import Select from "../common/Select";
import Input from "../common/Input";
import DataRow from "../common/DataRow";
import TextArea from "../common/TextArea";
import Button from "../common/Button";
import LocationSelector from "../../pages/events/_components/LocationSelector";
import Box from "../common/Box";
import ManageWebLinks from "./ManageWebLinks";

const CourseEventForm = ({ onCancel, isEdit = false, event, onSubmit }) => {
  const [course, setCourse] = useState("");
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [description, setDescription] = useState("");
  const [note, setNote] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [canSignUp, setCanSignUp] = useState(false);
  const [organiserName, setOrganiserName] = useState("");
  const [organiser, setOrganiser] = useState("");
  const [location, setLocation] = useState({});
  const [webLinks, setWebLinks] = useState([]);

  const [isExternalOrganiser, setIsExternalOrganiser] = useState("");
  const [pickableCourses, setPickableCourses] = useState([]);
  const [pickableMembers, setPickableMembers] = useState([]);
  const dateErrors = useMemo(() => {
    if(DayJS(start).isAfter(DayJS(end))){
      return "End date & time must be after start date & time"
    }
    if(DayJS(start).isSame(DayJS(end))){
      return "Start date & time must be different from end date & time"
    }

    return false;
  }, [start, end]);

  useEffect(() => {
    API.member.all().then((res) => {
      setPickableMembers(res);
    });
    API.courses.list().then((res) => {
      setPickableCourses(res);
    });
  }, []);
  useEffect(() => {
    if (isExternalOrganiser === "team" && organiserName?.length > 0) {
      setOrganiserName("");
    }
    if (isExternalOrganiser === "external" && organiser?.length > 0) {
      setOrganiser("");
    }
  }, [isExternalOrganiser, organiserName, organiser]);
  useEffect(() => {
    if (isEdit) {
      setCourse(
        ((event.course !== undefined && event.course !== null) ? event.course._id : event.course || "")
      );
      setTitle(event.title);
      setColor(event.color);
      setDescription(event.description);
      setNote(event.note);

      const aStart = DayJS(event.start);
      if(aStart.isValid()) setStart(aStart.toDate());
      const bEnd = DayJS(event.end);
      if(bEnd.isValid()) setEnd(bEnd.toDate());

      setCanSignUp(event.canSignUp);
      setLocation(event.location);
      if (event.webLinks !== undefined) setWebLinks(event.webLinks);

      if (event.organiser !== undefined && event.organiser._id !== undefined) {
        setIsExternalOrganiser("team");
        setOrganiser(event.organiser._id);
      } else {
        setIsExternalOrganiser("external");
        setOrganiserName(event.organiserName);
      }
    }
  }, [isEdit, event]);

  const onStartDateSelect = (date) => {
    if(DayJS(date).isAfter(DayJS(end))){
      Toast.error("End date & time updated as it was after the start date & time.");
      setEnd(date);
    }
    setStart(date);
  }

  const handleSubmit = () => {
    const data = {
      course,
      title,
      color,
      description,
      note,
      start,
      end,
      canSignUp,
      location,
      webLinks,
    };

    if (organiserName !== "") {
      data.organiserName = organiserName;
    }
    if (organiser !== "") {
      data.organiser = organiser;
    }

    onSubmit(data);
  };

  return (
    <div>
      <div className={"flex gap-4"}>
        <div className={"w-full"}>
          <div className="w-full">
            <Select
              label="Course"
              options={pickableCourses.map((item) => {
                return {
                  key: item._id,
                  text: item.name,
                  value: item._id,
                };
              })}
              value={course}
              onChange={(e) => setCourse(e.target.value)}
            />
          </div>
          {course && (
            <>
              <div className="w-full">
                <Input
                  type="text"
                  label={"Title"}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </div>
              <div className="flex gap-4">
                <div className="w-full">
                  <label htmlFor={"date"} className={`labelClass`}>
                    Start
                  </label>
                  <DatePicker
                    onChange={(date) => onStartDateSelect(date)}
                    selected={start}
                    dateFormat="dd-MM-yy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className={`w-full rounded border ${dateErrors === false ? "border-pp dark:border-zinc-500" : "border-red-500 dark:border-red-800"} bg-white p-1 dark:bg-zinc-700`}
                    data-disable-touch-keyboard
                  />
                </div>
                <div className="w-full">
                  <label htmlFor={"date"} className={`labelClass`}>
                    End
                  </label>
                  <DatePicker
                    onChange={(date) => setEnd(date)}
                    selected={end}
                    minDate={start}
                    dateFormat="dd-MM-yy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    className={`w-full rounded border ${dateErrors === false ? "border-pp dark:border-zinc-500" : "border-red-500 dark:border-red-800"} bg-white p-1 dark:bg-zinc-700`}
                    data-disable-touch-keyboard
                  />
                </div>
              </div>
              {dateErrors && <div className={"text-red-400 p-2"}>{dateErrors}</div>}

              <TextArea
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <TextArea
                label="Notes (Private)"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <div className="flex gap-4">
                <div className="w-full">
                  <label htmlFor={"color"} className={`labelClass`}>
                    Calender Color
                  </label>
                  <CompactPicker
                    triangle="hide"
                    color={color}
                    onChangeComplete={(e) => setColor(e.hex)}
                  />
                </div>
                <div className="w-full">
                  <Select
                    label="Allow Self Signup"
                    options={[
                      { key: 1, value: "false", text: "No" },
                      { key: 2, value: "true", text: "Yes" },
                    ]}
                    value={canSignUp}
                    onChange={(e) => setCanSignUp(e.target.value)}
                  />

                  <Select
                    label="Organiser Type"
                    options={[
                      { key: 1, value: "team", text: "Team Member" },
                      { key: 2, value: "external", text: "External" },
                    ]}
                    value={isExternalOrganiser}
                    onChange={(e) => setIsExternalOrganiser(e.target.value)}
                  />
                </div>
              </div>
              {isExternalOrganiser === "external" && (
                <div className="w-full">
                  <Input
                    type="text"
                    label={"External Organiser's Name"}
                    onChange={(e) => setOrganiserName(e.target.value)}
                    value={organiserName}
                  />
                </div>
              )}
              {isExternalOrganiser === "team" && (
                <Select
                  label="Team Member"
                  options={pickableMembers.map((item) => {
                    return {
                      key: item._id,
                      value: item._id,
                      text: item.firstName + " " + item.lastName,
                    };
                  })}
                  value={organiser}
                  onChange={(e) => setOrganiser(e.target.value)}
                />
              )}
            </>
          )}
        </div>
        {course && (
          <div className={"w-full"}>
            {location?.properties !== undefined ? (
              <div className="">
                <label htmlFor={"date"} className={`labelClass`}>
                  Location
                </label>
                <DataRow label="Name" value={location.properties.name} />
                <DataRow label="Town" value={location.properties.town} />
                <DataRow
                  label="Postcode"
                  value={location.properties.postcode}
                />
                <Button
                  text="Change Location"
                  onClick={() => setLocation({})}
                />
              </div>
            ) : (
              <Box title="Location">
                <LocationSelector
                  location={location}
                  onChange={setLocation}
                />
              </Box>
            )}

            <Box title={"Web Links"} className={"pt-5"}>
              <ManageWebLinks links={webLinks} onChange={links => setWebLinks(links)} />
            </Box>
          </div>
        )}
      </div>

      <div className="mt-4 flex justify-between border-t border-zinc-500 pt-4">
        {onCancel && (
          <button
            className="rounded-lg bg-primary px-4 py-2 text-white"
            onClick={onCancel}
          >
            Cancel
          </button>
        )}
        {course && (
          <button
            className="rounded-lg bg-green-500 px-4 py-2 text-white disabled:opacity-60"
            onClick={handleSubmit}
            disabled={dateErrors !== false}
          >
            {isEdit ? "Update" : "Create"}
          </button>
        )}
      </div>
    </div>
  );
};
export default CourseEventForm;