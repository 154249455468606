import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import ChainedContext from "../../../contexts/chainedContext";
import SettingsReceiver from "../../../contexts/settings_context/Settings_Receiver";
import Box from "../../../components/common/Box";
import API from "../../../api";

const CalendarFeeds = ({ settings, onSubmit }) => {
  const [calendarFeedUrls, setCalendarFeedUrls] = useState({
    global: "",
    recruitment: ""
  });

  useEffect(() => {
    API.calendar.retrieveFeedUrls("global").then(res => {
      setCalendarFeedUrls(res);
    })
  }, [settings.enableCalendarFeeds])

  return (
    <Box title="iCal Calendar Feeds">
      <div className={"p-3"}>
        <label className="labelClass">Enable Calendar Web Feed?</label>
        <ToggleSwitch
          isChecked={settings.enableCalendarFeeds}
          label1={"No"}
          label2={"Yes"}
          onChange={() => {
            onSubmit({enableCalendarFeeds: !settings.enableCalendarFeeds});
          }}
        />
      </div>

      {settings.enableCalendarFeeds && <>
        <div>
          <label className="labelClass pl-2">Global Calendar</label>
          <div className="flex w-full items-center gap-2 p-2 align-middle">
            <input
              value={calendarFeedUrls.global}
              className="w-full rounded-md border-gray-400 bg-white p-2 shadow-sm dark:bg-zinc-700"
              type={"text"}
              onChange={() => {}}
            />
            <button
              onClick={() => {
                navigator.clipboard.writeText(calendarFeedUrls.global).then(() => {
                  toast.success("Copied to clipboard");
                }).catch(err => {
                  toast.error("Unable to copy URL to clipboard: " + err.message)
                })
              }}
              className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-green-500 hover:border-green-300"
            >Copy</button>
          </div>
        </div>
        <div>
          <label className="labelClass pl-2">Recruitment Only Calendar</label>
          <div className="flex w-full items-center gap-2 p-2 align-middle">
            <input
              value={calendarFeedUrls.recruitment}
              className="w-full rounded-md border-gray-400 bg-white p-2 shadow-sm dark:bg-zinc-700"
              type={"text"}
              onChange={() => {}}
            />
            <button
              onClick={() => {
                navigator.clipboard.writeText(calendarFeedUrls.recruitment).then(() => {
                  toast.success("Copied to clipboard");
                }).catch(err => {
                  toast.error("Unable to copy URL to clipboard: " + err.message)
                })
              }}
              className="h-10 cursor-pointer rounded-md border border-green-500 bg-green-300 bg-opacity-40 px-4 py-2 text-green-500 hover:border-green-300"
            >Copy</button>
          </div>
        </div>
        <p className={"px-4 my-5 text-sm text-center"}>Note some applications like Outlook and Google Calendar only refresh these feeds every 6 to 24 hours. There is no consistency with this and is purely a there limitation not ours.<br /><br />Personal feeds available within the TeamSite Mobile App.</p>
      </>}
    </Box>
  );
};

export default ChainedContext(CalendarFeeds, [
  [SettingsReceiver, "settings"]
]);