import React from "react";
import { Switch } from "@headlessui/react";

const ToggleSwitch = ({
  label,
  label1,
  label2,
  isChecked,
  onChange,
  disabled = false,
  posColour = "bg-blue-600",
  negColour = "bg-gray-500",
}) => {
  return (
    <div className={`flex text-primary dark:text-white`}>
      <Switch.Group>
        {label1 && (
          <Switch.Label className={`mr-2 ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}>{label1}</Switch.Label>
        )}
        <Switch
          checked={isChecked}
          onChange={() => onChange(!isChecked)}
          disabled={disabled}
          className={`${
            isChecked ? posColour : negColour
          } relative inline-flex h-6 w-11 items-center rounded-xl disabled:cursor-not-allowed`}
        >
          {/* <span className="sr-only">Enable notifications</span> */}
          <span
            className={`${
              isChecked ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-xl bg-white`}
          />
        </Switch>
        {label2 && (
          <Switch.Label className={`ml-2 ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}>{label2}</Switch.Label>
        )}
        {label && (
          <Switch.Label className={`ml-2 ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}>{label}</Switch.Label>
        )}
      </Switch.Group>
    </div>
  );
};
export default ToggleSwitch;
