import React, { useState, useEffect,useMemo } from "react";
import DayJS from "dayjs";
import {useNavigate} from "react-router-dom";
import Box from "../../components/common/Box";
import Stat from "../../components/common/Stat";
import api from "../../api";

function IncidentDashboard() {
  const [incidents, setIncidents] = useState([]);
  const stats = useMemo(() => {
    return {
      total: incidents.length,
      thisYear: incidents.reduce((acc, incident) => {
        if(DayJS(incident.opened).year() === DayJS().year()){
          return acc + 1;
        }
        return acc;
      }, 0),
      thisMonth: incidents.reduce((acc, incident) => {
        if(DayJS(incident.opened).year() === DayJS().year() && DayJS(incident.opened).month() === DayJS().month()){
          return acc + 1;
        }
        return acc;
      }, 0)
    }
  }, [incidents]);
  const navigation = useNavigate();

  useEffect(() => {
    api.incidents.allIncidents().then(res => {
      setIncidents(res.toSorted((a, b) => {
        if(a.opened < b.opened) return 1;
        if(a.opened > b.opened) return -1;
        return 0;
      }));
    })
  }, []);

  return (
    <div className="w-full p-2">
      <Box title="Incidents">
        <div className="flex justify-around gap-4 p-2">
          <Stat label={"All Time"} value={stats.total} />
          <Stat label={"This Year"} value={stats.thisYear} />
          <Stat label={"This Month"} value={stats.thisMonth} />
        </div>
        <div>
          <table className="tableClass">
            <thead className="tableHeadClass">
              <tr className="tableHeadRowClass">
                <th className="tableHeadCellClass w-20">ID</th>
                <th className="tableHeadCellClass">Name</th>
                <th className="tableHeadCellClass">Type</th>
                <th className="tableHeadCellClass w-30">Date</th>
                <th className="tableHeadCellClass w-30">Op Periods</th>
                <th className="tableHeadCellClass">Outcome</th>
              </tr>
            </thead>
            <tbody className={"tableBodyClass"}>{incidents.map(item => {
              return (
                <tr key={item._id} className={"tableBodyRowClass cursor-pointer"} onClick={() => navigation(`/incidents/view/${item._id}`)}>
                  <td className={"tableBodyCellClass"}>{item.refNum}</td>
                  <td className={"tableBodyCellClass"}>{item.name}</td>
                  <td className={"tableBodyCellClass uppercase"}>{item.type}</td>
                  <td className={"tableBodyCellClass"}>{DayJS(item.opened).format("DD-MM-YYYY")}</td>
                  <td className={"tableBodyCellClass"}>{item._periodCount}</td>
                  <td className={"tableBodyCellClass"}>{item.outcome?.length > 60 ? item.outcome.substring(0, 60) + "..." : item.outcome}</td>
                </tr>
              );
            })}</tbody>
          </table>
        </div>
      </Box>
    </div>
  );
}
export default IncidentDashboard;